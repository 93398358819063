import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import LandingPage from './pages/LandingPage';

const About = lazy(() => import('./pages/About'));
const Schedule = lazy(() => import('./pages/Schedule'));
const Contact = lazy(() => import('./pages/Contact'));
const ContactForm = lazy(() => import('./components/ContactForm'));
const PrivacyPolicyTerms = lazy(() => import('./pages/PrivacyPolicyTerms'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));

// Custom loading component
const LoadingFallback = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundImage: `url(/assets/background-sky.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    }}
  >
    <CircularProgress size={60} thickness={4} sx={{ mb: 2 }} />
    <Typography variant="h5" sx={{ color: '#fff', fontWeight: 'bold' }}>
      Please wait, loading...
    </Typography>
  </Box>
);

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact-form" element={<ContactForm />} />
        <Route path="/privacy-policy-and-terms-of-service" element={<PrivacyPolicyTerms />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/*" element={<LandingPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
