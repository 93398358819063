import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import HeroSection from '../components/HeroSection';
import HighlightReel from '../components/HighlightReel';
import Map from '../components/Map';
import ContactForm from '../components/ContactForm';
import { generatePresignedUrl } from '../clientWasabi'; // Import Wasabi client logic

const LandingPage = () => {
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    const fetchPresignedUrl = async () => {
      try {
        const url = await generatePresignedUrl('lfcf', 'LFCF-IMAGES/Hero Video/1.mp4');
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching pre-signed URL for video:', error);
      }
    };

    fetchPresignedUrl();
  }, []);


  

  return (
    <div className="bg-test">
      {videoUrl && (
        <HeroSection
          videoSrc={videoUrl}
          title="Welcome to Living Faith Christian Fellowship"
          scrollToId="#Highlights"
          preload="metadata" // Only load video metadata
          poster="/assets/background-sky.png" // Add a placeholder poster
         
        />
      )}
      <Box sx={{ paddingBottom: 30 }}>
        <HighlightReel />
        <Map />
      </Box>
      <ContactForm />
    </div>
  );
};

export default LandingPage;
