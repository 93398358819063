import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, TextField, Button, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FacebookIframeWithCTA from './FacebookIframeWithCTA';
import { generatePresignedUrl } from '../clientWasabi'; // Import Wasabi logic

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: '100px',
  paddingBottom: '100px',
  color: theme.palette.text.primary,
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
}));

const BackgroundVideo = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 0,
  opacity: 0.5,
});

const ContentWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
});

const SectionTitle = styled(Typography)({
  marginBottom: '16px',
  textAlign: 'center',
  color: 'white',
  textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black',
});

const ContactPaper = styled(Paper)(({ theme }) => ({
  padding: '32px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: '8px',
  boxShadow: theme.shadows[3],
  fontFamily: 'Oswald, sans-serif',
  maxWidth: '700px',
  zIndex: 1,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '16px',
  '& .MuiInputBase-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.text.primary,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.text.primary,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontFamily: 'Oswald, sans-serif',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const bibleVerses = [
  "John 3:16 - For God so loved the world...",
  "Jeremiah 29:11 - For I know the plans I have for you...",
  "Philippians 4:13 - I can do all things through Christ...",
  "Romans 8:28 - And we know that in all things...",
  "Psalm 23:1 - The Lord is my shepherd...",
  "Proverbs 3:5 - Trust in the Lord with all your heart...",
  "Matthew 6:33 - But seek first his kingdom...",
  "Isaiah 41:10 - So do not fear, for I am with you...",
  "Matthew 11:28 - Come to me, all who labor...",
  "2 Timothy 1:7 - For God gave us a spirit not of fear..."
];

const ContactForm = () => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchPresignedUrl = async () => {
      try {
        const url = await generatePresignedUrl('lfcf', 'LFCF-IMAGES/Main/Living-Faith-Christian-Fellowship-Contact-Background.mp4');
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching pre-signed URL for video:', error);
      }
    };

    fetchPresignedUrl();
  }, []);

  return (
    <StyledContainer maxWidth={false}>
      {videoUrl && (
        <BackgroundVideo autoPlay loop muted>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </BackgroundVideo>
      )}
      <ContentWrapper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <SectionTitle variant="h3">Contact Form</SectionTitle>

        <Box sx={{ maxWidth: '350px', marginBottom: '24px', borderRadius: 20 }}>
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop
            autoPlay
            interval={5000}
            dynamicHeight={false}
          >
            {bibleVerses.map((verse, index) => (
              <Box key={index} sx={{ height: 'auto', padding: '16px', backgroundColor: '#fff', color: '#000', borderRadius: '50px' }}>
                <Typography variant="h5" sx={{ fontFamily: 'Oswald, sans-serif' }}>
                  {verse}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>

        <ContactPaper sx={{ width: '90vw' }} elevation={3}>
          <form name="contact" method="POST" action="/thank-you" data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
            <input type="hidden" name="form-name" value="contact" />

            <p hidden>
              <label>Don't fill this out if you're human: <input name="bot-field" /></label>
            </p>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField fullWidth name="first-name" label="First Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField fullWidth name="last-name" label="Last Name" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField fullWidth name="email" label="Email" type="email" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField fullWidth name="message" label="Message" variant="outlined" multiline rows={4} />
              </Grid>
              <Grid item xs={12}>
                <StyledButton variant="contained" type="submit" fullWidth>
                  Submit
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </ContactPaper>

        <FacebookIframeWithCTA />
      </ContentWrapper>
    </StyledContainer>
  );
};

export default ContactForm;
