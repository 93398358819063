import React, { Suspense } from 'react';
import { Box, Typography, Button } from '@mui/material';
import FallbackSpinner from './FallbackSpinner';

const HeroSection = ({ videoSrc, title, scrollToId }) => {
  return (
    <Box
      id="hero-section"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: {
          xs: '100vh',
          md: '100vh'
        },
        overflow: 'hidden',
      }}
    >
      
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          background: 'radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
        }}
      />
     
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          color: 'white',
          padding: '20px',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '3.5rem', md: '7rem' },
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.6)',
          }}
        >
          {title}
        </Typography>
        <Button
        className="bounce-in-top"
          component="a"
          href={scrollToId}
          variant="outlined"
          sx={{
            marginTop: '20px', 
            borderColor: 'white',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            padding: '5px 20px',
            fontSize: '1.2rem',
            textTransform: 'none',
            borderRadius: '25px',
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: 'black',
              borderColor: 'black',
            },
          }}
        >
          Learn More
        </Button>
      </Box>
      <Suspense fallback={<FallbackSpinner />} >
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 0,
          opacity: 0.5,
        }}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      </Suspense>
    </Box>
  );
};

export default HeroSection;
