import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

// Initialize the S3 client with credentials and endpoint
const s3 = new S3Client({
  endpoint: 'https://s3.us-east-1.wasabisys.com',
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_WASABI_SECRET_KEY,
  },
});

// Generate a pre-signed URL for a specific object
export const generatePresignedUrl = async (bucket, key) => {
  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });

  try {
    const url = await getSignedUrl(s3, command, { expiresIn: 300 }); // URL valid for 5 minutes
    return url;
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    throw error;
  }
};
